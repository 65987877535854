$(document).ready(function () {
	/* - - - - - - - - - - - - - -      main benefit slider        - - - - - - - - - - - - - - - */
	$(".js-main-benefit-info-slider").on(
		"beforeChange",
		function (event, slick, currentSlide, nextSlide) {
			$(".js-main-benefit-info-slider .slick-slide").addClass(
				"opacity-0"
			);
			setTimeout(() => {
				$(".js-main-benefit-info-slider .slick-slide").removeClass(
					"opacity-0"
				);
			}, 500);
		}
	);

	// $(".js-main-benefit-info-slider").on(
	// 	"afterChange",
	// 	function (event, slick, currentSlide, nextSlide) {
	// 		$(".js-main-benefit-info-slider .slick-slide").removeClass("opacity-0");
	// 	}
	// );

	$(".js-main-benefit-info-slider").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		dots: false,
		arrows: false,
		speed: 1500,
		asNavFor: ".js-main-benefit-slider",
	});

	$(".js-main-benefit-slider").on("init", function (event, slick) {
		const $this = $(this);
		const $list = $this.find(".slick-list");
		setTimeout(() => {
			$this.css("min-height", `calc(1px + ${$list.css("height")})`);
		}, 1000);

		$(window).resize(function () {
			setTimeout(() => {
				$this.css("min-height", `calc(1px + ${$list.css("height")})`);
			}, 1000);
		});
	});

	$(".js-main-benefit-slider").on(
		"beforeChange",
		function (event, slick, currentSlide, nextSlide) {
			let slidesLength = slick.$slides.length - 1,
				isCurrentFirstOrLast =
					currentSlide === 0 || currentSlide === slidesLength,
				isNextFirstOrLast =
					nextSlide === 0 || nextSlide === slidesLength;

			if (isCurrentFirstOrLast && isNextFirstOrLast) {
				let nextClone = $(event.currentTarget).find(
					".slick-cloned.slick-active"
				);
				setTimeout(function () {
					nextClone.addClass("slick-current");
				}, 100);
			}
		}
	);

	$(".js-main-benefit-slider").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: ".js-main-benefit-info-slider",
		arrows: true,
		dots: false,
		centerMode: true,
		speed: 1500,
		appendArrows: ".main-benefit__slider-btn",
	});

	/* - - - - - - - - - - - - - -      main benefit slider        - - - - - - - - - - - - - - - */
	$(".js-main-features-slider").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		speed: 1000,
		appendArrows: ".js-main-features-slider-arrow",
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	/* - - - - - - - - - - - - - -       init main-info slider       - - - - - - - - - - - - - - - */
	function initMainInfoSlider() {
		if (window.matchMedia("(max-width: 575px)").matches) {
			$(".js-main-info-slider").slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: false,
				speed: 600,
				cssEase: "linear",
			});
		}
	}
	initMainInfoSlider();
	$(window).resize(function () {
		if (
			$(".js-main-info-slider").hasClass("slick-initialized") &&
			window.matchMedia("(min-width: 576px)").matches
		) {
			$(".js-main-info-slider").slick("unslick");
		} else if (!$(".js-main-info-slider").hasClass("slick-initialized")) {
			initMainInfoSlider();
		}
	});

	/* - - - - - - - - - - - - - -       custom video block       - - - - - - - - - - - - - - - */
	function CustomVideoBlock() {
		$(".js-custom-video").each(function () {
			const $this = $(this);
			const $parent = $this.closest(".js-custom-video-box");
			const $mainVideoBlock = $this.closest(".js-main-video-block");
			const $play = $parent.find(".js-video-play");
			const $sound = $parent.find(".js-video-sound");
			const $soundOff = $sound.find(".video__sound-off");
			const $soundOn = $sound.find(".video__sound-on");
			const videoControl = $this[0];

			const withtoutClick = $this.attr('data-without-click')

			if(!withtoutClick) {
				$this.on("click", function () {
					if (videoControl.paused) {
						videoControl.play();
						$play.addClass("hidden");
						$sound.removeClass("opacity-0");
					} else {
						videoControl.pause();
						$mainVideoBlock.removeClass("active");
						$play.removeClass("hidden");
						$sound.addClass("opacity-0");
					}
				});
			}

			$sound.on("click", function () {
				videoControl.muted = !videoControl.muted;
				if (videoControl.muted) {
					$soundOff.removeClass("d-none");
					$soundOn.addClass("d-none");
				} else {
					$soundOff.addClass("d-none");
					$soundOn.removeClass("d-none");
				}
			});

			$this.on("play", function () {
				$mainVideoBlock.addClass("active");
				$play.addClass("hidden");
				$sound.removeClass("opacity-0");
			});
			$this.on("stop", function () {
				$mainVideoBlock.removeClass("active");
				$play.removeClass("hidden");
				$sound.addClass("opacity-0");
			});

			$this.on("ended", function () {
				$mainVideoBlock.removeClass("active");
				$play.removeClass("hidden");
				$sound.addClass("opacity-0");
			});

			$play.on("click", function () {
				if (videoControl.paused) {
					videoControl.play();
					$play.addClass("hidden");
					$sound.removeClass("opacity-0");
					$mainVideoBlock.addClass("active");
				} else {
					videoControl.pause();
					$play.removeClass("hidden");
					$sound.addClass("opacity-0");
					$mainVideoBlock.removeClass("active");
				}
			});
		});
	}
	if ($(".js-custom-video").length) {
		CustomVideoBlock();
	}

	/* - - - - - - - - - - - - - -       replay video        - - - - - - - - - - - - - - - */
	function ReplayVideo() {
		$(".js-replay-video").each(function () {
			const $this = $(this);
			const videoControl = $this[0];
			$this.on("ended", function () {
				videoControl.currentTime = 0;
				videoControl.play();
			});
		});
	}
	if ($(".js-replay-video").length) {
		ReplayVideo();
	}

	/* - - - - - - - - - - - - - -     number animation       - - - - - - - - - - - - - - - */
	function numberAnimation(item) {
		const $this = $(item);
		// if (!$this.hasClass("active")) {
		$this.prop("Counter", 0).animate(
			{
				Counter: $this.text(),
			},
			{
				duration: 2000,
				easing: "swing",
				step: function (now) {
					$this.text(Math.ceil(now));
				},
			}
		);
		// 	$this.addClass("active");
		// }
	}

	/* - - - - - - - - - - - - - -      fullPage.js        - - - - - - - - - - - - - - - */
	const animationOnLeave = function (
		classArr = [],
		indexArr = [],
		currentSlideName,
		nextSlideName,
		isShowHeaderGradient,
		nextSlideHeaderGradientDarkClass,
		nextSlideHeaderGradientWhiteClass,
		index,
		nextIndex
	) {
		classArr.map((item) => {
			if ($(`${item}`).is("*")) {
				const $block = $(`${item}`);
				const $parent = $block.closest("[data-slide-name]");
				const parentDataSlideName = $parent.data("slide-name");
				if (
					// indexArr.indexOf(parentDataSlideName) >= 0 &&
					nextSlideName === parentDataSlideName
				) {
					if (item === ".js-main-info-content") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 2 }
						);
						gsap.fromTo(
							item,
							{ y: nextIndex > index ? 200 : "-200" },
							{ y: 0, duration: 2 }
						);
					}
					// if (item === ".js-main-info-content-text") {
					// 	gsap.fromTo(
					// 		item,
					// 		{ opacity: 0 },
					// 		{ opacity: 1, duration: 2 }
					// 	);
					// 	gsap.fromTo(
					// 		item,
					// 		{ y: nextIndex > index ? 200 : "-200" },
					// 		{ y: 0, duration: 2 }
					// 	);
					// }
					if (item === ".js-main-product-title") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 3 }
						);
					}
					if (item === ".js-main-product-drones") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 3 }
						);
						gsap.fromTo(
							item,
							{ y: nextIndex > index ? 200 : "-200" },
							{ y: 0, duration: 2 }
						);
					}
					if (item === ".js-main-product-drones-list") {
						const dronesChildren = $(item).children();
						let delay = 2.5;
						let setTimeOutDelay = 1;
						dronesChildren.each(function (index) {
							const $this = $(this);
							gsap.fromTo(
								$this,
								{ opacity: 0 },
								{ opacity: 1, duration: 3 },
								delay
							);
							if (index === 0) {
								$this.addClass("active");
								setTimeout(() => {
									$this.addClass("animate");
								}, 1000);
							}
							delay += 0.5;
							setTimeOutDelay += 0.5;
						});
					}
					if (item === ".js-main-product-text") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 3 },
							2.5
						);
					}
					if (item === ".js-main-benefit-content") {
						gsap.fromTo(
							item,
							{ y: nextIndex > index ? 200 : "-200" },
							{ y: 0, duration: 2 }
						);
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 2 }
						);
					}
					if (item === ".js-main-video-block") {
						// setTimeout(() => {
						// 	if (
						// 		window.matchMedia("(min-width: 992px)").matches
						// 	) {
						// 		gsap.fromTo(
						// 			item,
						// 			{ padding: 96 },
						// 			{ padding: 0, duration: 1 }
						// 		);
						// 	}
						// 	if (
						// 		window.matchMedia("(min-width: 768px)").matches
						// 	) {
						// 		gsap.fromTo(
						// 			item,
						// 			{ padding: 80 },
						// 			{ padding: 0, duration: 1 }
						// 		);
						// 	}
						// 	// const $video = $parent.find(".js-custom-video");
						// 	// if ($video.is("*")) {
						// 	// 	const videoControl = $video[0];
						// 	// 	videoControl.play();
						// 	// }
						// }, 1000);
					}
					if (item === ".js-main-banner-title") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{
								opacity: 1,
								duration: nextIndex > index ? 0.5 : 1.5,
							}
						);
						gsap.fromTo(
							item,
							{ y: nextIndex > index ? 25 : "-25" },
							{ y: 0, duration: nextIndex > index ? 0.5 : 1.5 }
						);
					}
					if (item === ".js-main-banner-text") {
						setTimeout(() => {
							gsap.fromTo(
								item,
								{ opacity: 0 },
								{ opacity: 1, duration: 0.5 }
							);
						}, 500);
					}
					if (item === ".js-main-banner-btn-read") {
						setTimeout(
							() => {
								gsap.fromTo(
									item,
									{ opacity: 0 },
									{ opacity: 1, duration: 0.5 }
								);
							},
							nextIndex > index ? 400 : 0
						);
						setTimeout(
							() => {
								gsap.fromTo(
									item,
									{ x: -25 },
									{ x: 0, duration: 0.4 }
								);
							},
							nextIndex > index ? 500 : 0
						);
					}
					if (item === ".js-main-banner-btn-more") {
						setTimeout(
							() => {
								gsap.fromTo(
									item,
									{ y: -10 },
									{ y: 0, duration: 0.4 }
								);
								gsap.fromTo(
									item,
									{ opacity: 0 },
									{ opacity: 1, duration: 0.5 }
								);
							},
							nextIndex > index ? 500 : 0
						);
					}
					if (item === ".js-main-banner-bg") {
						gsap.fromTo(
							item,
							{ transform: "scale(1.1)" },
							{ transform: "scale(1)", duration: 2 }
						);
					}
					if (item === ".js-news-grid") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.fromTo(item, { x: -25 }, { x: 0, duration: 1 });
					}
					if (item === ".js-news-prev") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.fromTo(item, { x: 15 }, { x: 0, duration: 1 });
					}
					if (item === ".js-news-next") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.fromTo(item, { x: -15 }, { x: 0, duration: 1 });
					}
					if (item === ".js-news-control-time") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.fromTo(item, { x: -25 }, { x: 0, duration: 1 });
					}
					if (item === ".js-news-control-progress") {
						const mobile =
							window.matchMedia("(max-width: 768px)").matches;
						const tablet =
							window.matchMedia("(max-width: 991px)").matches;
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.fromTo(
							item,
							{
								width: 0,
							},
							{
								width: mobile ? "100%" : tablet ? 217 : 272,
								duration: 1.5,
							}
						);
					}
					if (item === ".js-news-read") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.fromTo(
							item,
							{ y: nextIndex > index ? 25 : "-25" },
							{ y: 0, duration: 1.5 }
						);
					}
					if (item === ".js-news-banner-info") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.fromTo(
							item,
							{ y: nextIndex > index ? 25 : "-25" },
							{ y: 0, duration: 1.5 }
						);
					}
					if (item === ".js-news-detail-slider") {
						setTimeout(() => {
							// gsap.fromTo(
							// 	item,
							// 	{ x: "-80%" },
							// 	{ x: 0, duration: 1 }
							// );
							gsap.fromTo(
								item,
								{ opacity: 0 },
								{ opacity: 1, duration: 3 }
							);
						}, 500);
					}
					if (item === ".js-news-detail-slider-text") {
						setTimeout(() => {
							gsap.fromTo(
								item,
								{ opacity: 0 },
								{ opacity: 1, duration: 1 }
							);
							gsap.fromTo(item, { x: 50 }, { x: 0, duration: 1 });
						}, 1000);
					}
					if (
						item === ".js-desc-heading" ||
						item === ".js-desc-big"
					) {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1.5 }
						);
					}
					if (item === ".js-desc-small") {
						setTimeout(() => {
							gsap.fromTo(
								item,
								{ opacity: 0 },
								{ opacity: 1, duration: 1.5 }
							);
						}, 500);
					}
					if (item === ".js-product-info-inner") {
						setTimeout(() => {
							gsap.fromTo(
								item,
								{ opacity: 0 },
								{ opacity: 1, duration: 2 }
							);
						}, 500);
					}
					if (item === ".js-product-info-point") {
						if (window.matchMedia("(min-width: 768px)").matches) {
							setTimeout(() => {
								gsap.fromTo(
									item,
									{ width: 0, height: 0 },
									{ width: 29, height: 29, duration: 0.5 }
								);
							}, 500);
						} else {
							setTimeout(() => {
								gsap.fromTo(
									item,
									{ width: 0, height: 0 },
									{ width: 20, height: 20, duration: 0.5 }
								);
							}, 500);
						}
					}
				}
				if (currentSlideName === parentDataSlideName) {
					if (item === ".js-main-info-content") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.to(item, {
							y: nextIndex > index ? "-200" : 200,
							duration: 1,
						});
					}
					// if (item === ".js-main-info-content-text") {
					// 	gsap.fromTo(
					// 		item,
					// 		{ opacity: 1 },
					// 		{ opacity: 0, duration: 1 }
					// 	);
					// 	gsap.to(item, {
					// 		y: nextIndex > index ? "-200" : 200,
					// 		duration: 1,
					// 	});
					// }
					if (item === ".js-main-product-title") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
					}
					if (item === ".js-main-product-drones") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.fromTo(
							item,
							{ y: 0 },
							{ y: nextIndex > index ? "-200" : 200, duration: 1 }
						);
					}
					if (item === ".js-main-product-drones-list") {
						const dronesChildren = $(item).children();
						dronesChildren.each(function (index) {
							const $this = $(this);
							gsap.fromTo(
								$this,
								{ opacity: 1 },
								{ opacity: 0, duration: 1 }
							);
						});
					}
					if (item === ".js-main-product-text") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
					}
					if (item === ".js-main-benefit-content") {
						gsap.fromTo(
							item,
							{ y: 0 },
							{ y: nextIndex > index ? "-200" : 200, duration: 1 }
						);
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
					}
					if (item === ".js-main-banner-title") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{
								opacity: 0,
								duration: nextIndex > index ? 1.5 : 0.5,
							}
						);
						gsap.fromTo(
							item,
							{ y: 0 },
							{
								y: nextIndex > index ? "-25" : 0,
								duration: nextIndex > index ? 1.5 : 0.5,
							}
						);
					}
					if (item === ".js-main-banner-text") {
						setTimeout(() => {
							gsap.fromTo(
								item,
								{ opacity: 1 },
								{ opacity: 0, duration: 0.5 }
							);
						}, 500);
					}
					if (item === ".js-main-banner-btn-read") {
						setTimeout(
							() => {
								gsap.fromTo(
									item,
									{ opacity: 1 },
									{ opacity: 0, duration: 0.5 }
								);
							},
							nextIndex > index ? 0 : 400
						);
						setTimeout(
							() => {
								gsap.fromTo(
									item,
									{ x: 0 },
									{ x: -25, duration: 0.4 }
								);
							},
							nextIndex > index ? 0 : 500
						);
					}
					if (item === ".js-main-banner-btn-more") {
						setTimeout(
							() => {
								gsap.fromTo(
									item,
									{ y: 0 },
									{ y: -10, duration: 0.4 }
								);
								gsap.fromTo(
									item,
									{ opacity: 1 },
									{ opacity: 0, duration: 0.5 }
								);
							},
							nextIndex > index ? 0 : 500
						);
					}
					if (item === ".js-main-banner-bg") {
						gsap.fromTo(
							item,
							{ transform: "scale(1)" },
							{ transform: "scale(1.1)", duration: 2 }
						);
					}
					if (item === ".js-news-grid") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.fromTo(item, { x: 0 }, { x: -25, duration: 1 });
					}
					if (item === ".js-news-prev") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.fromTo(item, { x: 0 }, { x: 15, duration: 1 });
					}
					if (item === ".js-news-next") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.fromTo(item, { x: 0 }, { x: -15, duration: 1 });
					}
					if (item === ".js-news-control-time") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.fromTo(item, { x: 0 }, { x: -25, duration: 1 });
					}
					if (item === ".js-news-control-progress") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.to(item, {
							width: 0,
							duration: 1.5,
						});
					}
					if (item === ".js-news-read") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.fromTo(
							item,
							{ y: 0 },
							{ y: nextIndex > index ? 25 : "-25", duration: 1.5 }
						);
					}
					if (item === ".js-news-banner-info") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.fromTo(
							item,
							{ y: 0 },
							{ y: nextIndex > index ? 25 : "-25", duration: 1.5 }
						);
					}
					if (item === ".js-news-detail-slider") {
						// gsap.fromTo(item, { x: 0 }, { x: "-80%", duration: 1 });
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 3 }
						);
					}
					if (item === ".js-news-detail-slider-text") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
						gsap.fromTo(item, { x: 0 }, { x: 50, duration: 1 });
					}
					if (
						item === ".js-desc-heading" ||
						item === ".js-desc-big"
					) {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
					}
					if (item === ".js-desc-small") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
					}
					if (item === ".js-product-info-inner") {
						gsap.fromTo(
							item,
							{ opacity: 1 },
							{ opacity: 0, duration: 1 }
						);
					}
					if (item === ".js-product-info-point") {
						if (window.matchMedia("(min-width: 768px)").matches) {
							setTimeout(() => {
								gsap.fromTo(
									item,
									{ width: 29, height: 29 },
									{ width: 0, height: 0, duration: 0.5 }
								);
							}, 500);
						} else {
							setTimeout(() => {
								gsap.fromTo(
									item,
									{ width: 20, height: 20 },
									{ width: 0, height: 0, duration: 0.5 }
								);
							}, 500);
						}
					}
				}
				if (nextSlideName === "video") {
					$(".header-logo").addClass("opacity-0");
					$(".header-back").addClass("opacity-0");
					$(".header-toggle").addClass("opacity-0");
				} else {
					if ($(".header-logo").hasClass("opacity-0")) {
						$(".header-logo").removeClass("opacity-0");
					}

					if ($(".header-back").hasClass("opacity-0")) {
						$(".header-back").removeClass("opacity-0");
					}

					if ($(".header-toggle").hasClass("opacity-0")) {
						$(".header-toggle").removeClass("opacity-0");
					}
				}
				if (
					nextSlideName === parentDataSlideName &&
					$(".js-theme-checkbox").is("*")
				) {
					const value = $(".js-theme-checkbox").is(":checked");
					$(".header-gradient").removeClass("style-white");
					$(".header-gradient").removeClass("style-dark");
					$(".header-gradient").removeClass("style-gray");
					if (nextSlideHeaderGradientDarkClass) {
						$(".header-gradient").attr(
							"data-dark-drone-bg-class",
							nextSlideHeaderGradientDarkClass
						);
					}
					if (nextSlideHeaderGradientWhiteClass) {
						$(".header-gradient").attr(
							"data-white-drone-bg-class",
							nextSlideHeaderGradientWhiteClass
						);
					}
					if (value) {
						$(".header-gradient").addClass(
							nextSlideHeaderGradientDarkClass
						);
					} else {
						$(".header-gradient").addClass(
							nextSlideHeaderGradientWhiteClass
						);
					}
				}
			}
		});

		if (isShowHeaderGradient) {
			$(".header-gradient").addClass("opacity-0");
		} else {
			if ($(".header-gradient").hasClass("opacity-0")) {
				$(".header-gradient").removeClass("opacity-0");
			}
		}
	};

	const animationAfterLoad = function (
		classArr = [],
		indexArr = [],
		currentSlide
	) {
		classArr.map((item) => {
			if ($(`${item}`).is("*")) {
				const $block = $(`${item}`);
				const $parent = $block.closest("[data-slide-name]");
				const parentDataSlideName = $parent.data("slide-name");
				if (currentSlide === parentDataSlideName) {
					if (item === ".js-main-banner-title") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 0.5 }
						);
						gsap.from(item, {
							y: 25,
							duration: 0.5,
						});
					}
					if (item === ".js-main-banner-text") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 0.5 },
							0.5
						);
					}
					if (item === ".js-main-banner-btn-read") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 0.5 },
							0.4
						);
						gsap.from(
							item,
							{
								x: -25,
								duration: 0.4,
							},
							0.5
						);
					}
					if (item === ".js-main-banner-btn-more") {
						gsap.from(
							item,
							{
								y: -10,
								duration: 0.4,
							},
							0.4
						);
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 0.5 },
							0.4
						);
					}
					if (item === ".js-main-banner-bg") {
						gsap.fromTo(
							item,
							{ transform: "scale(1.1)" },
							{ transform: "scale(1)", duration: 2 }
						);
					}
					if (item === ".js-news-grid") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.from(item, {
							x: -25,
							duration: 1,
						});
					}
					if (item === ".js-news-prev") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.from(item, {
							x: 15,
							duration: 1,
						});
					}
					if (item === ".js-news-next") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.from(item, {
							x: -15,
							duration: 1,
						});
					}
					if (item === ".js-news-control-time") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.from(item, {
							x: -25,
							duration: 1,
						});
					}
					if (item === ".js-news-control-progress") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.from(item, {
							width: 0,
							duration: 1.5,
						});
					}
					if (item === ".js-news-read") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.from(item, {
							y: -25,
							duration: 1.5,
						});
					}
					if (item === ".js-news-banner-info") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 1 }
						);
						gsap.from(item, {
							y: 25,
							duration: 1.5,
						});
					}
					if (item === ".js-main-product-title") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 3 }
						);
					}
					if (item === ".js-main-product-drones") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 3 }
						);
						gsap.from(item, {
							y: 200,
							duration: 2,
						});
					}
					if (item === ".js-main-product-drones-list") {
						const dronesChildren = $(item).children();
						let delay = 0.5;
						let setTimeOutDelay = 1;
						dronesChildren.each(function (index) {
							const $this = $(this);
							const $link = $this.find("a");
							gsap.fromTo(
								$this,
								{ opacity: 0 },
								{ opacity: 1, duration: 3 },
								delay
							);
							if (index === 0) {
								$this.addClass("active");
								setTimeout(() => {
									$this.addClass("animate");
								}, 1000);
							}
							delay += 0.5;
							setTimeOutDelay += 0.5;
						});
					}
					if (item === ".js-main-product-text") {
						gsap.fromTo(
							item,
							{ opacity: 0 },
							{ opacity: 1, duration: 3 }
						);
					}
				}
			}
		});
	};

	function fullPageIndexPage() {
		// let prevIndex = 0
		let indexArr = [];
		let allSlidesDataName = [];
		$("[data-slide-name]").each(function () {
			const $this = $(this);
			allSlidesDataName.push($this.data("slide-name"));
		});
		const fullPageChildren = $(".fullPage").children();
		$(".fullPage").fullpage({
			anchors: [],
			scrollOverflow: true,
			scrollingSpeed: 1400,
			scrollOverflowOptions: {
				disableMouse: window.matchMedia("(max-width: 991px)").matches
					? false
					: true,
				disablePointer: window.matchMedia("(max-width: 991px)").matches
					? false
					: true,
			},
			keyboardScrolling: false,
			onLeave: function (index, nextIndex, direction) {
				// prevIndex =
				const $currentSlide = $(fullPageChildren[index - 1]);
				const currentSlideName = $currentSlide.data("slide-name");
				const $nextSlide = $(fullPageChildren[nextIndex - 1]);
				const nextSlideName = $nextSlide.data("slide-name");
				const isHideHeaderGradient = $nextSlide.hasClass(
					"js-without-header-bg"
				);
				const nextSlideHeaderGradientDarkClass = $nextSlide.data(
					"header-gradient-dark-bg-class"
				);
				const nextSlideHeaderGradientWhiteClass = $nextSlide.data(
					"header-gradient-white-bg-class"
				);

				// if (nextIndex > index) {
				animationOnLeave(
					[
						".js-main-info-content",
						".js-main-info-content-text",
						".js-main-product-title",
						".js-main-product-drones",
						".js-main-product-drones-list",
						".js-main-product-text",
						".js-main-benefit-content",
						".js-main-banner-title",
						".js-main-banner-text",
						".js-main-banner-btn-read",
						".js-main-banner-btn-more",
						".js-main-banner-bg",
						".js-news-grid",
						".js-news-prev",
						".js-news-next",
						".js-news-control-time",
						".js-news-control-progress",
						".js-news-read",
						".js-news-banner-info",
						".js-main-video-block",
						".js-news-detail-slider",
						".js-news-detail-slider-text",
						".js-desc-heading",
						".js-desc-big",
						".js-desc-small",
						".js-product-info-inner",
						".js-product-info-point",
					],
					allSlidesDataName,
					currentSlideName,
					nextSlideName,
					isHideHeaderGradient,
					nextSlideHeaderGradientDarkClass,
					nextSlideHeaderGradientWhiteClass,
					index,
					nextIndex
				);

				const $thisSlide = $(fullPageChildren[index - 1]);
				const $videoBlocks = $thisSlide.find("[data-play-one-time]");
				$videoBlocks.each(function () {
					const $thisVideoBlock = $(this);
					if (
						$thisVideoBlock[0].hasAttribute("data-autoplay") &&
						$thisVideoBlock[0].ended
					) {
						// I don't want to stop it if is not finished, or if I already removed the data-autoplay.
						$thisVideoBlock[0].removeAttribute("data-autoplay");
					}

					if (
						$thisVideoBlock[0].hasAttribute("playsinline") &&
						$thisVideoBlock[0].ended
					) {
						// I don't want to stop it if is not finished, or if I already removed the playsinline.
						$thisVideoBlock[0].removeAttribute("playsinline");
					}
				});

				if ($nextSlide.find(".js-count-number")) {
					$nextSlide.find(".js-count-number").each(function () {
						numberAnimation($(this));
					});
				}

				if ($nextSlide.data("product-toggle-theme-need")) {
					if ($nextSlide.hasClass("main-product")) {
						if (window.matchMedia("(min-width: 1200px)").matches) {
							$("[data-product-toggle-theme]").removeClass(
								"opacity-0"
							);
						} else {
							$("[data-product-toggle-theme]").addClass(
								"opacity-0"
							);
						}
					} else {
						$("[data-product-toggle-theme]").removeClass(
							"opacity-0"
						);
					}
				} else {
					$("[data-product-toggle-theme]").addClass("opacity-0");
				}

				const $stopVideoOnLeave = $currentSlide.find('.js-stop-on-leave')
				if($stopVideoOnLeave.length) {
					$stopVideoOnLeave[0].pause()
					if($currentSlide.hasClass('main-video')) {
						const $mainVideoBlock = $stopVideoOnLeave.closest(".js-main-video-block");
						const $play = $mainVideoBlock.find(".js-video-play");
						const $sound = $mainVideoBlock.find(".js-video-sound");
						$mainVideoBlock.removeClass("active");
						$play.removeClass("hidden");
						$sound.addClass("opacity-0");
					}
				}
			},
			afterLoad: function (anchorLink, index) {
				const $this = $(this);
				const dataSlideName = $this.data("slide-name");
				const slideIndex = allSlidesDataName.indexOf(dataSlideName);
				if (index === 1 && slideIndex >= 0) {
					animationAfterLoad(
						[
							".js-main-banner-title",
							".js-main-banner-text",
							".js-main-banner-btn-read",
							".js-main-banner-btn-more",
							".js-main-banner-bg",
							".js-main-video-block",
							".js-news-banner-info",
							".js-news-grid",
							".js-news-prev",
							".js-news-next",
							".js-news-control-time",
							".js-news-control-progress",
							".js-news-read",
							".js-main-product-title",
							".js-main-product-drones",
							".js-main-product-drones-list",
							".js-main-product-text",
						],
						allSlidesDataName,
						dataSlideName
					);
					allSlidesDataName.splice(slideIndex, 1);
				} else if (slideIndex >= 0) {
					allSlidesDataName.splice(slideIndex, 1);
				}

				if (index === 1) {
					if ($this.data("product-toggle-theme-need")) {
						if ($this.hasClass("main-product")) {
							if (
								window.matchMedia("(min-width: 1200px)").matches
							) {
								$("[data-product-toggle-theme]").removeClass(
									"opacity-0"
								);
							} else {
								$("[data-product-toggle-theme]").addClass(
									"opacity-0"
								);
							}
						} else {
							$("[data-product-toggle-theme]").removeClass(
								"opacity-0"
							);
						}
					}
				}

				// if ($this.data("product-slide-info")) {
				// 	productInfoPointDuplicate(true, true);
				// }
			},
		});
	}
	fullPageIndexPage();

	// $(window).resize(function () {
	// 	$.fn.fullpage.destroy("all");
	// 	fullPageIndexPage()
	// });

	$(".js-fullpage-next-slide").on("click", function () {
		$.fn.fullpage.moveSectionDown();
	});

	/* - - - - - - - - - - - - - -       header nav img animation        - - - - - - - - - - - - - - - */
	$(".js-header-nav-item").on("mouseover", function () {
		const $this = $(this);
		const dataIndex = $this.data("nav-index");
		$(".js-header-nav-item").removeClass("active");
		$this.addClass("active");

		$(".js-header-nav-item-img").each(function () {
			const $thisImg = $(this);
			const dataIndexImg = $thisImg.data("nav-img");
			if ($thisImg.hasClass("active") && dataIndexImg !== dataIndex) {
				$thisImg.removeClass("active");
				$thisImg.addClass("prev");
				setTimeout(() => {
					$thisImg.removeClass("prev");
				}, 100);
			} else {
				$thisImg.removeClass("prev");
			}
			if (dataIndexImg == dataIndex) {
				$thisImg.addClass("active");
			}
		});
	});

	$(".js-header-nav-item").on("mouseout", function () {
		const $this = $(this);
		$this.removeClass("active");

		$(".header-menu__backgrouhnd__img").addClass("overlay");

		$(".js-header-nav-item-img").each(function () {
			const $thisImg = $(this);
			$thisImg.removeClass("active");
			$thisImg.removeClass("prev");
		});
	});

	/* - - - - - - - - - - - - - -      	header menu animation       - - - - - - - - - - - - - - - */
	const headerAnimationOpen = () => {
		// Background
		if ($(".js-header-menu-bg").is("*")) {
			if (window.matchMedia("(min-width: 1600px)").matches) {
				gsap.fromTo(
					".js-header-menu-bg",
					{ width: "0" },
					{ width: "60.7%", duration: 1.25 }
				);
				gsap.fromTo(
					".js-header-menu-bg",
					{ "border-left-width": "0" },
					{ "border-left-width": "25.8vw ", duration: 1.25 }
				);
			} else if (window.matchMedia("(min-width: 1400px)").matches) {
				gsap.fromTo(
					".js-header-menu-bg",
					{ width: "0" },
					{ width: "67.7%", duration: 1.25 }
				);
				gsap.fromTo(
					".js-header-menu-bg",
					{ "border-left-width": "0" },
					{ "border-left-width": "25.8vw ", duration: 1.25 }
				);
			} else if (window.matchMedia("(min-width: 1200px)").matches) {
				gsap.fromTo(
					".js-header-menu-bg",
					{ width: "0" },
					{ width: "80.7%", duration: 1.25 }
				);
				gsap.fromTo(
					".js-header-menu-bg",
					{ "border-left-width": "0" },
					{ "border-left-width": "28.8vw ", duration: 1.25 }
				);
			} else {
				gsap.fromTo(
					".js-header-menu-bg",
					{ width: "0" },
					{ width: "100%", duration: 1.25 }
				);
			}
		}

		// Background opacity

		if ($(".js-header-menu-bg-opaity").is("*")) {
			setTimeout(() => {
				gsap.fromTo(
					".js-header-menu-bg-opaity",
					{ opacity: "0" },
					{ opacity: "0.4", duration: 1.25 }
				);
			}, 312);
		}

		// Btn back
		if ($(".js-header-menu-back").is("*")) {
			setTimeout(() => {
				gsap.fromTo(
					".js-header-menu-back",
					{ opacity: 0 },
					{ opacity: 1, duration: 0.625 }
				);
				gsap.fromTo(
					".js-header-menu-back",
					{ x: 50 },
					{ x: 0, duration: 0.625 }
				);
			}, 312);
		}

		// Navigation
		if ($(".js-header-menu-nav").is("*")) {
			// setTimeout(() => {
			// 	gsap.fromTo(
			// 		".js-header-menu-nav",
			// 		{ opacity: 0 },
			// 		{ opacity: 1, duration: 0.625 }
			// 	);
			// 	gsap.fromTo(
			// 		".js-header-menu-nav",
			// 		{
			// 			y: "100%",
			// 		},
			// 		{
			// 			y: "0",
			// 			duration: 0.625,
			// 		}
			// 	);
			// }, 500);

			const navChildren = $(".js-header-menu-nav ul").children();
			let delay = 1000;
			navChildren.each(function (index) {
				const $this = $(this);
				setTimeout(() => {
					gsap.fromTo(
						$this,
						{ opacity: 0 },
						{ opacity: 1, duration: 1 }
					);
				}, delay);

				delay += 250;
			});
		}

		// Info
		if ($(".js-header-menu-info").is("*")) {
			setTimeout(() => {
				gsap.fromTo(
					".js-header-menu-info",
					{ opacity: 0 },
					{ opacity: 1, duration: 1.25 }
				);
			}, 625);
		}
	};

	const headerAnimationClose = () => {
		// Background

		if ($(".js-header-menu-back").is("*")) {
			setTimeout(() => {
				if (window.matchMedia("(min-width: 1600px)").matches) {
					gsap.fromTo(
						".js-header-menu-bg",
						{ "border-left-width": "25.8vw" },
						{ "border-left-width": "0vw", duration: 1.25 }
					);
					gsap.fromTo(
						".js-header-menu-bg",
						{ width: "60.7%" },
						{ width: "0", duration: 1.25 }
					);
				} else if (window.matchMedia("(min-width: 1400px)").matches) {
					gsap.fromTo(
						".js-header-menu-bg",
						{ width: "67.7%" },
						{ width: "0", duration: 1.25 }
					);
					gsap.fromTo(
						".js-header-menu-bg",
						{ "border-left-width": "25.8vw" },
						{ "border-left-width": "0", duration: 1.25 }
					);
				} else if (window.matchMedia("(min-width: 1200px)").matches) {
					gsap.fromTo(
						".js-header-menu-bg",
						{ width: "80.7%" },
						{ width: "0", duration: 1.25 }
					);
					gsap.fromTo(
						".js-header-menu-bg",
						{ "border-left-width": "28.8vw" },
						{ "border-left-width": "0", duration: 1.25 }
					);
				} else {
					gsap.fromTo(
						".js-header-menu-bg",
						{ width: "100%" },
						{ width: "0", duration: 1.25 }
					);
				}
			}, 312);
		}
		if ($(".js-header-menu-bg-opaity").is("*")) {
			gsap.fromTo(
				".js-header-menu-bg-opaity",
				{ opacity: "0.4" },
				{ opacity: "0", duration: 1.25 }
			);
		}

		// Btn back
		if ($(".js-header-menu-back").is("*")) {
			setTimeout(() => {
				gsap.fromTo(
					".js-header-menu-back",
					{ opacity: 1 },
					{ opacity: 0, duration: 0.625 }
				);
				gsap.fromTo(
					".js-header-menu-back",
					{ x: 0 },
					{ x: 50, duration: 0.625 }
				);
			}, 500);
		}

		// Navigation
		if ($(".js-header-menu-nav").is("*")) {
			// gsap.fromTo(
			// 	".js-header-menu-nav",
			// 	{ opacity: 1 },
			// 	{ opacity: 0, duration: 0.625 }
			// );
			// gsap.fromTo(
			// 	".js-header-menu-nav",
			// 	{
			// 		y: "0",
			// 	},
			// 	{
			// 		y: "100%",
			// 		duration: 0.625,
			// 	}
			// );

			const navChildren = $(".js-header-menu-nav ul").children();
			let delay = 0;

			for (let i = navChildren.length; i > 0; i--) {
				setTimeout(() => {
					gsap.fromTo(
						navChildren[i - 1],
						{ opacity: 1 },
						{ opacity: 0, duration: 0.1 }
					);
				}, delay);
				delay += 75;
			}
		}

		// Info
		if ($(".js-header-menu-info").is("*")) {
			gsap.fromTo(
				".js-header-menu-info",
				{ opacity: 1 },
				{ opacity: 0, duration: 0.625 }
			);
		}
	};

	/* - - - - - - - - - - - - - -       header menu toggle        - - - - - - - - - - - - - - - */
	$(".js-header-menu-toggle").on("click", function () {
		const $header = $("header");
		const $headerMenu = $(".header-menu");
		if ($headerMenu.hasClass("active")) {
			if ($("html").hasClass("fp-enabled")) {
				$.fn.fullpage.setAllowScrolling(true);
			}
			$(".js-header-nav-item-img").each(function () {
				const $thisImg = $(this);
				$thisImg.removeClass("active");
				$thisImg.removeClass("prev");
			});
			$(".js-header-nav-item").each(function () {
				const $thisNavItem = $(this);
				$thisNavItem.removeClass("active");
			});
			headerAnimationClose();

			$headerMenu.addClass("point-event-none");
			$(".js-header-menu-toggle").each(function () {
				$(this).addClass("point-event-none");
			});
			setTimeout(() => {
				$headerMenu.removeClass("active");
				$headerMenu.removeClass("point-event-none");
				$(".js-header-menu-toggle").each(function () {
					$(this).removeClass("point-event-none");
				});
			}, 3000);
		} else {
			$headerMenu.addClass("active");
			$(".js-header-menu-toggle").each(function () {
				$(this).addClass("point-event-none");
			});
			setTimeout(() => {
				$(".js-header-menu-toggle").each(function () {
					$(this).removeClass("point-event-none");
				});
			}, 3000);
			headerAnimationOpen();
			// $.fn.fullpage.setAllowScrolling(false);
			if ($("html").hasClass("fp-enabled")) {
				$.fn.fullpage.setAllowScrolling(false);
			}
		}
	});

	/* - - - - - - - - - - - - - -      main benefit slider        - - - - - - - - - - - - - - - */

	$(".js-news-detail-slider").on(
		"init reInit afterChange",
		function (event, slick, currentSlide, nextSlide) {
			let i = (currentSlide ? currentSlide : 0) + 1;
			let percent = i / slick.slideCount;

			const $current = $(".js-news-detail-slider-current");
			const $total = $(".js-news-detail-slider-total");
			if ($current.is("*")) {
				$current.text(i);
			}
			if ($total) {
				$total.text(`${slick.slideCount}`);
			}
		}
	);

	// $(".js-news-detail-slider").on(
	// 	"beforeChange",
	// 	function (event, slick, currentSlide, nextSlide) {
	// 		$(".slick-slide").addClass("opacity-0");
	// 	}
	// );

	// $(".js-news-detail-slider").on(
	// 	"afterChange",
	// 	function (event, slick, currentSlide, nextSlide) {
	// 		$(".slick-slide").removeClass("opacity-0");
	// 	}
	// );

	$(".js-news-detail-slider").slick({
		slidesToShow: 2,
		slidesToScroll: 1,
		fade: false,
		dots: false,
		arrows: true,
		speed: 1500,
		appendArrows: ".js-news-detail-slider-arrow",
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	});

	/* - - - - - - - - - - - - - -      main product tab change        - - - - - - - - - - - - - - - */
	$(".js-product-change-btn").on("click", function (e) {
		e.preventDefault();
		const $this = $(this);
		const productIndex = $this.data("main-product-index");
		const $parent = $this.parent();
		$(".js-product-change-btn").each(function () {
			// const $thisProduct = $(this)
			$(this).parent().removeClass("active");
			$(this).parent().removeClass("animate");
		});
		$parent.addClass("active");
		$parent.addClass("animate");

		$("[data-main-product-top-index]").each(function () {
			const $this = $(this);
			const index = $this.data("main-product-top-index");
			if (productIndex === index) {
				$this.removeClass("d-none");
			} else {
				$this.addClass("d-none");
			}
		});

		$("[data-main-product-desc-index]").each(function () {
			const $this = $(this);
			const index = $this.data("main-product-desc-index");
			if (productIndex === index) {
				$this.removeClass("d-none");
			} else {
				$this.addClass("d-none");
			}
		});

		$("[data-main-product-video-index]").each(function () {
			const $this = $(this);
			const index = $this.data("main-product-video-index");
			if (productIndex === index) {
				$this.removeClass("d-none");
				const $video = $this.find("video");
				if ($video) {
					const videoControl = $video[0];
					videoControl.currentTime = 0;
					videoControl.play();
				}
			} else {
				$this.addClass("d-none");
			}
		});
	});

	/* - - - - - - - - - - - - - -       news video       - - - - - - - - - - - - - - - */
	function newsVideo() {
		$(".js-news-video").each(function () {
			const $this = $(this);
			const $parent = $this.closest(".container");
			const $timeTotal = $parent.find(".js-news-video-time-total");
			const $timeCurrent = $parent.find(".js-news-video-time-current");

			const $progressBar = $parent.find(".js-news-video-progress");
			const $lineCurrent = $parent.find(
				".js-news-video-progress-current"
			);

			const videoControl = $this[0];

			videoControl.addEventListener(
				"loadeddata",
				function () {
					const durationHour = parseInt(
						videoControl.duration / 60 / 60,
						10
					);
					const durationMinutes = parseInt(
						videoControl.duration / 60,
						10
					);
					const durationSeconds = parseInt(
						videoControl.duration % 60
					);

					const corectHour =
						durationHour > 0
							? durationHour / 10 > 1
								? durationHour
								: `0${durationHour}`
							: "";
					const corectMinutes =
						durationMinutes > 0
							? durationMinutes / 10 > 1
								? durationMinutes
								: `0${durationMinutes}`
							: "00";
					const corectSeconds =
						durationSeconds > 0
							? durationSeconds / 10 > 1
								? durationSeconds
								: `0${durationSeconds}`
							: "00";

					const time = `${
						corectHour ? `${corectHour}:` : ""
					}${corectMinutes}:${corectSeconds}`;

					$timeTotal.text(time);
				},
				false
			);

			videoControl.addEventListener(
				"timeupdate",
				function () {
					let progress =
						Math.floor(videoControl.currentTime) /
						Math.floor(videoControl.duration);

					const currentHour = parseInt(
						videoControl.currentTime / 60 / 60,
						10
					);
					const currentMinutes = parseInt(
						videoControl.currentTime / 60,
						10
					);
					const currentSeconds = parseInt(
						videoControl.currentTime % 60
					);

					const corectHour =
						currentHour > 0
							? currentHour / 10 >= 1
								? currentHour
								: `0${currentHour}`
							: "";
					const corectMinutes =
						currentMinutes > 0
							? currentMinutes / 10 >= 1
								? currentMinutes
								: `0${currentMinutes}`
							: "00";
					const corectSeconds =
						currentSeconds > 0
							? currentSeconds / 10 >= 1
								? currentSeconds
								: `0${currentSeconds}`
							: "00";

					const time = `${
						corectHour ? `${corectHour}:` : ""
					}${corectMinutes}:${corectSeconds}`;

					$timeCurrent.text(time);
					if ($lineCurrent.is("*")) {
						$lineCurrent[0].style.width =
							100 *
								(videoControl.currentTime /
									videoControl.duration) +
							"%";
					}
				},
				false
			);

			$progressBar.on("click", function (event) {
				const w = this.offsetWidth;
				const o = event.offsetX;
				if ($lineCurrent.is("*")) {
					$lineCurrent[0].style.width = `${(100 * o) / w}%`;
				}
				videoControl.pause();
				videoControl.currentTime = videoControl.duration * (o / w);
				videoControl.play();
			});
		});
	}
	if ($(".js-news-video").length) {
		newsVideo();
	}

	/* - - - - - - - - - - - - - -       change product theme       - - - - - - - - - - - - - - - */
	$(".js-theme-checkbox").on("change", function (e) {
		const $this = $(this);
		const value = $this.is(":checked");
		if (value) {
			document.cookie = "themeMode=dark;path=/";
			$("[data-block-with-theme]").each(function () {
				const $this = $(this);
				const dataWhiteBgClass = $this[0].dataset.whiteDroneBgClass;
				const dataWhiteTextClass = $this.data("white-drone-text-class");
				const dataDarkBgClass = $this[0].dataset.darkDroneBgClass;
				const dataDarkTextClass = $this.data("dark-drone-text-class");

				$this.removeClass("style-white");
				$this.removeClass("style-dark");
				$this.removeClass("style-gray");

				$this.removeClass(dataWhiteBgClass);
				$this.removeClass(dataWhiteTextClass);

				$this.addClass(dataDarkBgClass);
				$this.addClass(dataDarkTextClass);
			});

			$("[data-theme-white]").each(function () {
				$(this).addClass("d-none");
			});
			$("[data-theme-dark]").each(function () {
				$(this).removeClass("d-none");
			});

			$("[data-theme-white-opacity]").each(function () {
				$(this).addClass("opacity-0");
			});
			$("[data-theme-dark-opacity]").each(function () {
				$(this).removeClass("opacity-0");
			});

			$("[data-theme-white-opacity-range]").each(function () {
				const $this = $(this);
				$(this).addClass("opacity-0");
			});
			$("[data-theme-dark-opacity-range]").each(function () {
				const $this = $(this);
				setTimeout(() => {
					$(this).removeClass("opacity-0");
				}, 1000);
			});

			$("[data-opacity-on-change-theme]").each(function () {
				const $this = $(this);
				$this.addClass("opacity-0");
				setTimeout(() => {
					$this.removeClass("opacity-0");
				}, 1000);
			});
			$("[data-theme-change-content]").each(function () {
				const $this = $(this);
				const contentDark = $this.attr("data-theme-dark-content");
				if ($this.closest("[data-opacity-on-change-theme]")) {
					setTimeout(() => {
						$this.text(contentDark);
					}, 1000);
				} else {
					$this.text(contentDark);
				}
			});
		} else {
			document.cookie = "themeMode=ligh;path=/";
			$("[data-block-with-theme]").each(function () {
				const $this = $(this);
				const dataWhiteBgClass = $this[0].dataset.whiteDroneBgClass;
				const dataWhiteTextClass = $this.data("white-drone-text-class");
				const dataDarkBgClass = $this[0].dataset.darkDroneBgClass;
				const dataDarkTextClass = $this.data("dark-drone-text-class");

				$this.removeClass("style-white");
				$this.removeClass("style-dark");
				$this.removeClass("style-gray");

				$this.removeClass(dataDarkBgClass);
				$this.removeClass(dataDarkTextClass);

				$this.addClass(dataWhiteBgClass);
				$this.addClass(dataWhiteTextClass);

				$("[data-opacity-on-change-theme]").each(function () {
					const $this = $(this);
					$this.addClass("opacity-0");
					setTimeout(() => {
						$this.removeClass("opacity-0");
					}, 1000);
				});
				$("[data-theme-change-content]").each(function () {
					const $this = $(this);
					const contentWhite = $this.attr("data-theme-white-content");
					if ($this.closest("[data-opacity-on-change-theme]")) {
						setTimeout(() => {
							$this.text(contentWhite);
						}, 1000);
					} else {
						$this.text(contentWhite);
					}
				});

				// $("[data-theme-change-content]").each(function() {
				// 	const $this = $(this)
				// 	const contentWhite = $this.attr("data-theme-white-content")
				// 	$this.text(contentWhite)
				// })
			});

			$("[data-theme-white]").each(function () {
				$(this).removeClass("d-none");
			});
			$("[data-theme-dark]").each(function () {
				$(this).addClass("d-none");
			});

			$("[data-theme-white-opacity]").each(function () {
				$(this).removeClass("opacity-0");
			});
			$("[data-theme-dark-opacity]").each(function () {
				$(this).addClass("opacity-0");
			});

			$("[data-theme-white-opacity-range]").each(function () {
				const $this = $(this);
				setTimeout(() => {
					$(this).removeClass("opacity-0");
				}, 1000);
			});
			$("[data-theme-dark-opacity-range]").each(function () {
				const $this = $(this);
				$(this).addClass("opacity-0");
			});
		}

		$("[data-product-intro-video]").each(function () {
			const $this = $(this)[0];
			if (!$this.hasAttribute("data-autoplay")) {
				$(this).attr("data-autoplay", "true");
			}

			if (!$this.hasAttribute("playsinline")) {
				$(this).attr("playsinline", "true");
			}

			$this.currentTime = 0;
			const $parent = $(this).closest(".section");
			if ($parent.hasClass("active")) {
				$this.play();
			}
		});

		const $infoBlock = $("[data-product-info-tab-block]");
		if ($infoBlock.is("*")) {
			$infoBlock.each(function () {
				const $thisBlock = $(this);
				const $blockRange = $thisBlock.find(".product-info__range");
				if (!$thisBlock.hasClass("d-none")) {
					const $togleTheme = $("[data-product-toggle-theme]");
					const $btnBack = $("[data-product-info-tab-main-back]");
					const showWhenVideoEndedBlocks = [];
					$thisBlock
						.find("[data-show-when-video-ended]")
						.each(function () {
							$(this).addClass("opacity-0");
							showWhenVideoEndedBlocks.push($(this));
						});

					$togleTheme.addClass("opacity-0");
					$btnBack.addClass("opacity-0");

					$blockRange.each(function () {
						const $thisBlockRange = $(this);
						$thisBlockRange.removeClass("opacity-0");
					});

					$thisBlock
						.find("[data-video-normal-wrapper]")
						.find("video")
						.each(function () {
							const $thisVideo = $(this);
							const autoPlayBlock = $thisVideo.closest(
								"[data-play-video-on-open]"
							);

							if (
								autoPlayBlock.is("*") ||
								window.matchMedia("(max-width: 1199px)").matches
							) {
								const videoControl = $thisVideo[0];
								videoControl.currentTime = 0;
								videoControl.play();

								$thisVideo.on("ended", function () {
									if (
										!$thisVideo.parent().hasClass("d-none")
									) {
										showWhenVideoEndedBlocks.forEach(
											(item) => {
												item.removeClass("opacity-0");
											}
										);
										$togleTheme.removeClass("opacity-0");
										$btnBack.removeClass("opacity-0");
									}
								});
							} else {
								if (!$thisVideo.parent().hasClass("d-none")) {
									setTimeout(() => {
										showWhenVideoEndedBlocks.forEach(
											(item) => {
												item.removeClass("opacity-0");
											}
										);
										$togleTheme.removeClass("opacity-0");
										$btnBack.removeClass("opacity-0");
									}, 1000);
								}
							}
						});
					// if (
					// 	$thisBlock.data("play-video-on-open") ||
					// 	window.matchMedia("(max-width: 1199px)").matches
					// ) {
					// 	$thisBlock.find("video").each(function () {
					// 		const $thisVideo = $(this);
					// 		const videoControl = $thisVideo[0];
					// 		videoControl.currentTime = 0;
					// 		videoControl.play();
					// 	});
					// }
				}
			});
		}

		// const $infoSlide = $("[data-product-slide-info]");
		// if ($infoSlide.is("*")) {
		// 	// productInfoPointDuplicate(true);
		// 	if (!$infoSlide.hasClass("active")) {
		// 		// productInfoPointDuplicate(false);
		// 	}
		// }

		if (window.matchMedia("(max-width: 768px)").matches) {
			$.fn.fullpage.reBuild();
		}
	});

	/* - - - - - - - - - - - - - -       product info open block       - - - - - - - - - - - - - - - */
	$("[data-product-info-tab-index]").on("click", function (e) {
		e.preventDefault();
		const $this = $(this);
		const dataIndex = $this.data("product-info-tab-index");

		$.fn.fullpage.setAllowScrolling(false);
		$("[data-product-info-tab-main]").addClass("open-details");
		$("[data-product-info-tab-block]").each(function () {
			const $thisBlock = $(this);
			const blockDataIndex = $thisBlock.data("product-info-tab-block");
			if (blockDataIndex === dataIndex) {
				$thisBlock.removeClass("d-none");
				const $botomContent = $thisBlock.find(".product-info__bottom");
				const $blockRange = $thisBlock.find(".product-info__range");
				const $togleTheme = $("[data-product-toggle-theme]");

				$togleTheme.addClass("opacity-0");

				$thisBlock
					.find("[data-video-normal-wrapper]")
					.find("video")
					.each(function () {
						const $thisVideo = $(this);
						const $thisVideoParent = $thisVideo.closest(
							".product-info__video-container"
						);

						const videoControl = $thisVideo[0];
						videoControl.currentTime = 0;
						if (!$thisVideoParent.hasClass("d-none")) {
							videoControl.play();
							$thisVideo.on("ended", function () {
								if (
									!$thisVideo
										.closest(
											"[data-product-info-tab-block]"
										)
										.hasClass("d-none")
								) {
									$blockRange.each(function () {
										const $thisBlockRange = $(this);
										$thisBlockRange.removeClass(
											"opacity-0"
										);
									});
									$botomContent.removeClass("opacity-0");
									$togleTheme.removeClass("opacity-0");
									$(
										"[data-product-info-tab-main-back]"
									).removeClass("opacity-0");
								}
							});
						} else {
							videoControl.currentTime = videoControl.duration;
						}
						$blockRange.each(function () {
							const $thisBlockRange = $(this);
							// if (!$thisBlockRange.parent().hasClass("opacity-0")) {
							const $input = $thisBlockRange
								.find("input")
								.data("ionRangeSlider");
							$input.update({
								from: 100,
							});
							// }
						});

						// if (
						// 	autoPlayBlock.is("*") ||
						// 	window.matchMedia("(max-width: 1199px)").matches
						// ) {

						// }
					});
				const $range = $thisBlock.find(".js-product-info-block-range");
				const $desc = $thisBlock.find(".js-product-info-block-desc");
				$range.css("width", $desc.width());

				if (window.matchMedia("(max-width: 768px)").matches) {
					$.fn.fullpage.reBuild();
				}
			} else {
				$thisBlock.addClass("d-none");
			}
		});
		const $productInfoPoint = $("[data-product-info-points-block]");
		if ($productInfoPoint.is("*")) {
			$productInfoPoint.addClass("opacity-0");
		}
	});

	$("[data-product-info-tab-main-back]").on("click", function (e) {
		e.preventDefault();

		function showMainData() {
			$("[data-product-info-tab-main]").removeClass("open-details");
			$("[data-video-index]").each(function () {
				const $this = $(this);
				$this[0].currentTime = 0;
			});
			$("[data-product-toggle-theme]").removeClass("opacity-0");

			const $productInfoPoint = $("[data-product-info-points-block]");
			if ($productInfoPoint.is("*")) {
				$productInfoPoint.removeClass("opacity-0");
				// productInfoPointDuplicate(true);
			}

			if (window.matchMedia("(max-width: 768px)").matches) {
				$.fn.fullpage.reBuild();
			}

			$.fn.fullpage.setAllowScrolling(true);
		}

		$("[data-product-info-tab-block]").each(function () {
			const $thisBlock = $(this);

			if (!$thisBlock.hasClass("d-none")) {
				let $videoReverse = null;

				// $thisBlock.addClass("d-none");
				$thisBlock
					.find(".product-info__bottom.v2")
					.addClass("opacity-0");
				$thisBlock.find(".product-info__range").addClass("opacity-0");
				$("[data-product-info-tab-main-back]").addClass("opacity-0");
				$("[data-product-toggle-theme]").addClass("opacity-0");

				const $videoReverseList = $thisBlock
					.find("[data-video-reverse-wrapper]")
					.find("video");
				$videoReverseList.each(function () {
					const $thisVideo = $(this);
					const $parent = $(this).closest(
						"[data-video-reverse-container]"
					);
					if (!$parent.hasClass("d-none")) {
						$videoReverse = $thisVideo;
					}
				});

				if ($videoReverse) {
					const $videoNormalWrapper = $thisBlock.find(
						"[data-video-normal-wrapper]"
					);
					const $videoReverseWrapper = $thisBlock.find(
						"[data-video-reverse-wrapper]"
					);
					$videoNormalWrapper.addClass("opacity-0");
					$videoReverseWrapper.removeClass("opacity-0");

					const videoReverseControl = $videoReverse[0];

					videoReverseControl.play();

					$videoReverse.on("ended", function () {
						const $thisEndVideo = $(this);

						if (
							!$thisEndVideo
								.closest("[data-video-reverse-wrapper]")
								.hasClass("d-none") &&
							!$thisEndVideo
								.closest("[data-product-info-tab-block]")
								.hasClass("d-none")
						) {
							$thisBlock.addClass("d-none");
							$videoNormalWrapper.removeClass("opacity-0");
							$videoReverseWrapper.addClass("opacity-0");

							showMainData();

							videoReverseControl.currentTime = 0;
						}
					});
				} else {
					$thisBlock.addClass("d-none");
					showMainData();
				}
			}
		});
	});

	/* - - - - - - - - - - - - - -       product info pin show info       - - - - - - - - - - - - - - - */
	function productDetailsPinHint() {
		$("[data-product-info-tab-index]").each(function () {
			const $this = $(this);
			const $hint = $this.find(".product-info__details__points-hint");
			const $hintRect = $hint[0].getBoundingClientRect();
			const windowWidth = $(window).width();
			if (windowWidth - $hintRect.x - $hintRect.width - 35 > 20) {
				$hint.addClass("show-right");
			} else {
				$hint.addClass("show-left");
			}
		});

		$("[data-product-info-tab-index]").on("mouseover", function () {
			if (window.matchMedia("(min-width: 992px)").matches) {
				const $this = $(this);
				$this.addClass("active");
				$this.css("z-index", 3);
			}
		});

		$("[data-product-info-tab-index]").on("mouseout", function () {
			if (window.matchMedia("(min-width: 992px)").matches) {
				const $this = $(this);
				$this.css("z-index", 2);
				$this.removeClass("active");
			}
		});
	}
	productDetailsPinHint();

	/* - - - - - - - - - - - - - -       product info range       - - - - - - - - - - - - - - - */
	$(".js-range-slider").each(function () {
		const $this = $(this);
		const $parent = $this.closest("[data-product-range-parent]");
		const $video = $parent.find("video ");
		$this.ionRangeSlider({
			min: 0,
			max: 100,
			grid: false,
			hide_from_to: true,
			hide_min_max: true,
			onChange: function (data) {
				const videoRangeIndex = $this.data("video-range-index");
				$video.each(function () {
					const $thisVideo = $(this);
					const videoIndex = $thisVideo.data("video-index");
					if (videoIndex === videoRangeIndex) {
						const thisVideoControl = $thisVideo[0];
						if (thisVideoControl.duration) {
							thisVideoControl.currentTime =
								thisVideoControl.duration * (data.from / 100);
						}
					}
				});
			},
		});
	});

	/* - - - - - - - - - - - - - -       product info range width      - - - - - - - - - - - - - - - */
	function productRangeWidth() {
		$("[data-product-range-parent]").each(function () {
			const $this = $(this);
			const $range = $this.find(".js-product-info-block-range");
			const $desc = $this.find(".js-product-info-block-desc");
			$range.css("width", $desc.width());
		});
	}

	$(window).resize(function () {
		productRangeWidth();
	});

	/* - - - - - - - - - - - - - -       contact points      - - - - - - - - - - - - - - - */
	$("[data-contact-point-name]").on("mouseover", function () {
		const $this = $(this);
		const dataPointName = $this.data("contact-point-name");
		$("[data-contact-section-name]").each(function () {
			const $thisContactSection = $(this);
			const dataContactSectionName = $thisContactSection.data(
				"contact-section-name"
			);
			if (dataPointName === dataContactSectionName) {
				$thisContactSection.removeClass("opacity-0");
			} else {
				$thisContactSection.addClass("opacity-0");
			}
		});
	});
	$("[data-contact-point-name]").on("mouseout", function () {
		const $this = $(this);
		const dataPointName = $this.data("contact-point-name");
		$("[data-contact-section-name]").each(function () {
			const $thisContactSection = $(this);
			const dataContactSectionName = $thisContactSection.data(
				"contact-section-name"
			);
			if (dataPointName === dataContactSectionName) {
				$thisContactSection.addClass("opacity-0");
			}
		});
	});

	/* - - - - - - - - - - - - - -       contact info slider       - - - - - - - - - - - - - - - */
	$(".js-contact-slider").on("init", function (event, slick) {
		const $this = $(this);
		const $list = $this.find(".slick-list");
		setTimeout(() => {
			$this.css("min-height", `calc(1px + ${$list.css("height")})`);
		}, 100);

		$(window).resize(function () {
			setTimeout(() => {
				$this.css("min-height", `calc(1px + ${$list.css("height")})`);
			}, 100);
		});
	});

	$(".js-contact-slider").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		centerMode: true,
		speed: 1000,
		appendArrows: ".contact-info__slider-btn",
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					arrows: false,
				},
			},
		],
	});

	/* - - - - - - - - - - - - - -       header navigation set       - - - - - - - - - - - - - - - */
	function setContactDesc() {
		if ($(".js-contact-info-desc-block").is("*")) {
			const $this = $(".js-contact-info-desc-block");
			const $deskPlace = $(".js-contact-info-desc-desk");
			const $tabletPlace = $(".js-contact-info-desc-tablet");
			const $mobPlace = $(".js-contact-info-desc-mob");
			$this.detach();
			if (window.matchMedia("(max-width: 991px)").matches) {
				$mobPlace.append($this);
			} else if (window.matchMedia("(max-width: 1191px)").matches) {
				$tabletPlace.append($this);
			} else {
				$deskPlace.append($this);
			}
		}
	}
	setContactDesc();
	$(window).on("resize", function () {
		setContactDesc();
	});

	/* - - - - - - - - - - - - - -       change input type text to input type email       - - - - - - - - - - - - - - - */
	function changeInputType() {
		if ($(".tnp-email").is("*")) {
			$(".tnp-email").each(function () {
				const $this = $(this);
				$this.prop("type", "email");
			});
		}
	}
	changeInputType();

	/* - - - - - - - - - - - - - -       change input type text to input type email       - - - - - - - - - - - - - - - */
	// function productInfoPointDuplicate(show, timeout) {
	// 	$("[data-product-info-point-dublicate]").each(function () {
	// 		const $this = $(this);
	// 		if (!window.matchMedia("(max-width: 767px)").matches) {
	// 			$this.addClass("opacity-0");
	// 			$(".product-info__details__points").each(function () {
	// 				$(this).removeClass("opacity-0");
	// 			});
	// 		} else {
	// 			$(".product-info__details__points").each(function () {
	// 				$(this).addClass("opacity-0");
	// 			});
	// 			if (show) {
	// 				$this.removeClass("opacity-0");
	// 				const duplicateIndex = $this.data(
	// 					"product-info-point-dublicate"
	// 				);
	// 				if(timeout) {
	// 					setTimeout(() => {
	// 						$("[data-product-info-point-index]").each(function () {
	// 							const $thisOriginalPoint = $(this);
	// 							const originalPointIndex = $thisOriginalPoint.data(
	// 								"product-info-point-index"
	// 							);
	// 							if (originalPointIndex === duplicateIndex) {
	// 								const originalPointRect =
	// 									$thisOriginalPoint[0].getBoundingClientRect();
	// 								$this.css("left", originalPointRect.x);
	// 								$this.css("top", originalPointRect.y);
	// 							}
	// 						});
	// 					}, 250)
	// 				} else {
	// 					$("[data-product-info-point-index]").each(function () {
	// 						const $thisOriginalPoint = $(this);
	// 						const originalPointIndex = $thisOriginalPoint.data(
	// 							"product-info-point-index"
	// 						);
	// 						if (originalPointIndex === duplicateIndex) {
	// 							const originalPointRect =
	// 								$thisOriginalPoint[0].getBoundingClientRect();
	// 							$this.css("left", originalPointRect.x);
	// 							$this.css("top", originalPointRect.y);
	// 						}
	// 					});
	// 				}
	// 			} else {
	// 				$this.addClass("opacity-0");
	// 			}
	// 		}
	// 	});
	// }

	// $(window).resize(function () {
	// 	const $infoSlide = $("[data-product-slide-info]");
	// 	if ($infoSlide.is("*")) {
	// 		productInfoPointDuplicate(true);
	// 		if (!$infoSlide.hasClass("active")) {
	// 			productInfoPointDuplicate(false);
	// 		}
	// 	}
	// });

	/* - - - - - - - - - - - - - -       app height       - - - - - - - - - - - - - - - */
	function appHeight(className) {
		if ($(className).is("*")) {
			$(className).css("min-height", `${window.innerHeight}px`);
		}
	}
	appHeight(".main-404__inner");
	appHeight(".contact-banner__inner");
	appHeight(".contact-info__inner");

	$("[data-start-product-video]").each(function () {
		const $this = $(this);
		const dataVideoName = $this.attr("data-start-product-video");
		const dataContentName = $this.attr("data-product-content-block-name");
		const $video = $(`[data-product-video="${dataVideoName}"]`);
		const $content = $(`[data-product-content-block="${dataContentName}"]`);
		const $togleTheme = $("[data-product-toggle-theme]");

		$video.on("ended", function () {
			$content.removeClass("opacity-0");
			if ($togleTheme.is("*")) {
				$togleTheme.removeClass("opacity-0");
			}
			$.fn.fullpage.setAllowScrolling(true);
			$.fn.fullpage.moveSectionDown();
		});
	});

	$("[data-start-product-video]").on("click", function (e) {
		e.preventDefault();
		const $this = $(this);
		const dataVideoName = $this.attr("data-start-product-video");
		const dataContentName = $this.attr("data-product-content-block-name");
		const $video = $(`[data-product-video="${dataVideoName}"]`);
		const $content = $(`[data-product-content-block="${dataContentName}"]`);
		const $togleTheme = $("[data-product-toggle-theme]");

		$.fn.fullpage.setAllowScrolling(false);

		$content.addClass("opacity-0");
		const videoControl = $video[0];
		videoControl.play();

		if ($togleTheme.is("*")) {
			$togleTheme.addClass("opacity-0");
		}
	});

	/* - - - - - - - - - - - - - -       Fullpage moveTo desc       - - - - - - - - - - - - - - - */
	$(".js-fullpage-move-to-desc").on("click", function (e) {
		e.preventDefault();

		let descSectionIndex = false
		$('.fullPage').children().each(function(index) {
			if($(this).hasClass('js-desc-full')) {
				descSectionIndex = index + 1
			}
		})
		if(descSectionIndex >= 0) {
			$.fn.fullpage.moveTo(descSectionIndex);
		}
	});

	/* - - - - - - - - - - - - - -      Feature box video on hover       - - - - - - - - - - - - - - - */
	$(".js-feature-box-video").on("mouseover", function () {
		if (window.matchMedia("(min-width: 992px)").matches) {
			const $this = $(this);
			const $video = $this.find("video");
			$video[0].play();
		}
	});

	$(".js-feature-box-video").on("mouseout", function () {
		if (window.matchMedia("(min-width: 992px)").matches) {
			const $this = $(this);
			const $video = $this.find("video");
			$video[0].pause();
		}
	});


});
